import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import * as Constants from '../constants';

import HtmlEvent from '../events/HtmlEvent';
import PicTextHelpEvent from '../events/PicTextHelpEvent';
import PicTextEvent from '../events/PicTextEvent';
import HtmlJsEvent from '../events/HtmlJsEvent';
import FeedbackEvent from '../events/FeedbackEvent';

const htmlDefault = {
  html: {
    ru: '<h3 style="text-align: center;"><span style="color: rgb(22, 145, 121);">Встреча</span></h3><img src="https://ourmind.ru/wp-content/uploads/2016/08/14c6f4173bed0f855f0de36a980.jpg" alt="" width="443" height="295">',
    de: '<h3 style="text-align: center;"><span style="color: rgb(22, 145, 121);">Treffen</span></h3><img src="https://ourmind.ru/wp-content/uploads/2016/08/14c6f4173bed0f855f0de36a980.jpg" alt="" width="443" height="295">',
  },
  type: 'html',
};

const htmljsDefault = {
  json: {
    ru: {
      time: 1691676887335,
      blocks: [
        {
          id: 'sheNwCUP5A',
          type: 'header',
          data: {
            text: 'Редактор react.js',
            level: 3,
          },
          tunes: {
            anyTuneName: {
              alignment: 'center',
            },
          },
        },
        {
          id: '12iM3lqzcm',
          type: 'paragraph',
          data: {
            text: '<b>Здравствуйте! </b>Это бесплатный редактор <b><font style="color: rgb(156, 39, 176);">react.js.</font></b>&nbsp;Он позволит Вам создавать контент данной веб страницы',
          },
        },
        {
          id: 'jYngOges6f',
          type: 'header',
          data: {
            text: 'С помощью него вы сможете:',
            level: 4,
          },
          tunes: {
            anyTuneName: {
              alignment: 'center',
            },
          },
        },
        {
          id: 'pIgLFCbQOP',
          type: 'list',
          data: {
            style: 'unordered',
            items: [
              'Добавлять <font style="color: rgb(3, 169, 244);"><b>разноцветный</b></font> текст',
              'Добавлять фотографии',
              'Добавлять таблицы',
              'Добавлять код',
              'И многое другое...',
            ],
          },
        },
        {
          id: 'utrxgZivDn',
          type: 'table',
          data: {
            withHeadings: false,
            content: [
              ['Телевизор', '1200', 'EUR'],
              ['Телефон', '1500', 'EUR'],
            ],
          },
        },
        {
          id: 'aGrhTmJipd',
          type: 'code',
          data: {
            code: 'const { t, i18n } = useTranslation();\nconst editorCore = useRef(null);',
          },
        },
        {
          id: 'Gi-QDFzbsE',
          type: 'image',
          data: {
            url: 'https://portaller.cloud/api/images/1691438212927.jpg',
            caption: '',
            withBorder: false,
            withBackground: true,
            stretched: false,
          },
        },
      ],
      version: '2.27.2',
    },
    de: {
      time: 1691676887335,
      blocks: [
        {
          id: 'sheNwCUP5A',
          type: 'header',
          data: {
            text: 'Editor react.js',
            level: 3,
          },
          tunes: {
            anyTuneName: {
              alignment: 'center',
            },
          },
        },
        {
          id: '12iM3lqzcm',
          type: 'paragraph',
          data: {
            text: '<b>Hallo! </b>Dies ist ein kostenloser Editor <b><font style="color: rgb(156, 39, 176);">react.js.</font></b>&nbsp;Er ​​wird es Ihnen ermöglichen um den Inhalt dieser Webseiten zu erstellen',
          },
        },
        {
          id: 'jYngOges6f',
          type: 'header',
          data: {
            text: 'Damit können Sie:',
            level: 4,
          },
          tunes: {
            anyTuneName: {
              alignment: 'center',
            },
          },
        },
        {
          id: 'pIgLFCbQOP',
          type: 'list',
          data: {
            style: 'unordered',
            items: [
              'Fügen Sie <font style="color: rgb(3, 169, 244);"><b>bunten</b></font> Text hinzu',
              'Fotos hinzufügen',
              'Tabellen hinzufügen',
              'Code hinzufügen',
              'Und vieles mehr...',
            ],
          },
        },
        {
          id: 'utrxgZivDn',
          type: 'table',
          data: {
            withHeadings: false,
            content: [
              ['Fernseher', '1200', 'EUR'],
              ['Telefon', '1500', 'EUR'],
            ],
          },
        },
        {
          id: 'aGrhTmJipd',
          type: 'code',
          data: {
            code: 'const { t, i18n } = useTranslation();\nconst editorCore = useRef(null);',
          },
        },
        {
          id: 'Gi-QDFzbsE',
          type: 'image',
          data: {
            url: 'https://portaller.cloud/api/images/1691438212927.jpg',
            caption: '',
            withBorder: false,
            withBackground: true,
            stretched: false,
          },
        },
      ],
      version: '2.27.2',
    },
  },
  type: 'htmljs',
};

const pictexthelpDefault = {
  pic: 'https://gamerwall.pro/uploads/posts/2022-06/1655380968_45-gamerwall-pro-p-tikhii-i-atlanticheskii-okean-priroda-kras-56.jpg',
  topic: {
    ru: 'Заголовок',
    de: 'Header',
  },
  text: {
    ru: 'Текст',
    de: 'Text',
  },
  type: 'pictexthelp',
  required: '100',
  collected: '0',
  helped: false,
};

const pictextDefault = {
  pic: '',
  topic: {
    ru: 'Заголовок',
    de: 'Header',
  },
  text: {
    ru: 'Текст',
    de: 'Text',
  },
  gallery: [],
  type: 'pictext',
};

const FeedbackDefault = {
  types: [
    {
      name: {
        ru: 'Запрос помощи',
        de: 'Hilfeanforderung',
      },
      emails: 'risesea@yandex.ru,rise33@yandex.ru',
    },
    {
      name: {
        ru: 'Предложение помощи',
        de: 'Hilfsangebot',
      },
      emails: 'risesea@yandex.ru,rise33@yandex.ru',
    },
  ],
  type: 'feedback',
};

const defEvents = {
  pictext: pictextDefault,
  html: htmlDefault,
  pictexthelp: pictexthelpDefault,
  htmljs: htmljsDefault,
  feedback: FeedbackDefault,
};

const Events_ = {
  html: HtmlEvent,
  pictexthelp: PicTextHelpEvent,
  pictext: PicTextEvent,
  htmljs: HtmlJsEvent,
  feedback: FeedbackEvent,
};

const Events = ({ getToken, settings, config, userdata }) => {
  const [eventsProps, setEvents] = React.useState([]);
  const [edit, setEdit] = React.useState(false);
  const [eventToAdd, setEventToAdd] = React.useState({});
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setColsNumber();
    const get = async () => {
      await editEventsReq();
      await getAllEvents();
    };
    get();
  }, []);

  //запрос данных страницы новостей
  const getAllEvents = async () => {
    const query = settings.query;
    await axios
      .get(Constants.URL + '/' + settings.urlprefix + '/get', { params: query })
      .then((res) => {
        setEvents(res.data);
      })
      .catch((err) => console.log(err?.response?.data?.message));
  };

  //запрос разрешения на редактирование страницы новостей
  const editEventsReq = async () => {
    const token = getToken();
    if (token) {
      const bearer = { headers: { Authorization: 'Bearer ' + token } };
      await axios
        .get(Constants.URL + '/' + settings.urlprefix + '/edit/request', bearer)
        .then((res) => {
          setEdit(true);
          console.log('users ', res.data);
        })
        .catch((err) => console.log(err?.response?.data?.message));
    } else {
      console.log('User not login');
    }
  };

  //редактирование новости
  const editOneEvent = async (postData) => {
    const token = getToken();
    if (token) {
      const bearer = { headers: { Authorization: 'Bearer ' + token } };
      await axios
        .post(Constants.URL + '/' + settings.urlprefix + '/edit', postData, bearer)
        .then((res) => console.log(res.data))
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log('User not login');
    }
  };

  //добавление новой новости
  const addOneEvent = async (postData) => {
    const token = getToken();
    if (token) {
      const bearer = { headers: { Authorization: 'Bearer ' + token } };
      await axios
        .post(Constants.URL + '/' + settings.urlprefix + '/add', postData, bearer)
        .then((res) => console.log(res.data))
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log('User not login');
    }
  };

  //добавление новой новости
  const delOneEvent = async (postData) => {
    const token = getToken();
    if (token) {
      const bearer = { headers: { Authorization: 'Bearer ' + token } };
      await axios
        .post(Constants.URL + '/' + settings.urlprefix + '/del', postData, bearer)
        .then((res) => console.log(res.data))
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log('User not login');
    }
  };

  //сохранение страницы новостей
  const saveEvent = (event) => {
    const save = async () => {
      await editOneEvent(event);
      await getAllEvents();
    };
    save();
  };

  const addEvent = (event) => {
    const save = async () => {
      await addOneEvent(event);
      await getAllEvents();
    };
    save();
  };

  const delEvent = (event) => {
    const del = async () => {
      await delOneEvent(event);
      await getAllEvents();
    };
    del();
  };

  const styles = {
    col1: 'grid gap-4 scrollbar-hide grid-cols-1',
    col2: 'grid gap-4 scrollbar-hide grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 justify-items-center',
    col3: 'grid gap-4 scrollbar-hide grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3 justify-items-center',
  };

  const [style, setStyle] = React.useState(styles.col3);

  function setColsNumber() {
    switch (settings.colsnum) {
      case 1:
        setStyle(styles.col1);
        break;
      case 2:
        setStyle(styles.col2);
        break;
      case 3:
        setStyle(styles.col3);
        break;
      default:
        setStyle(styles.col3);
    }
  }

  return (
    <div>
      {/* Если требуется меню добавления event-ов (будет переписано в отдельный компонент)*/}
      {edit && settings.addPanel && (
        <div class='flex w-full bg-white shadow-2xl border border-gray-200'>
          <div class='flex select-none items-center justify-center w-full'>
            <select
              onChange={(e) => setEventToAdd(defEvents[e.target.value])}
              class='w-1/2 m-1 p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500'>
              <option value='unknown' selected>
                {t('cardType')}
              </option>
              {settings.events.pictext && <option value='pictext'>{t('pictext')}</option>}
              {settings.events.pictexthelp && (
                <option value='pictexthelp'>{t('pictexthelp')}</option>
              )}
              {settings.events.html && <option value='html'>{t('html')}</option>}
              {settings.events.htmljs && <option value='htmljs'>{t('htmljs')}</option>}
              {settings.events.feedback && <option value='feedback'>{t('feedback')}</option>}
            </select>
            <button
              onClick={() => addEvent(eventToAdd)}
              class='w-1/2 m-1 p-1 text-sm hover:bg-green-200 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500'>
              {'Добавить'}
            </button>
          </div>
        </div>
      )}
      {/* Создаем event-ы*/}
      <div class='m-4'>
        <div class={style}>
          {eventsProps.map((event, index) => {
            if (typeof Events_[event.type] !== 'undefined') {
              return React.createElement(Events_[event.type], {
                key: index,
                event: event,
                saveEvent: saveEvent,
                edit: edit,
                delEvent: delEvent,
                settings: settings,
                getToken: getToken,
                config: config,
                userdata: userdata,
              });
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default Events;
