import React, { useState, useEffect, useRef, useCallback } from 'react';

import { useTranslation } from 'react-i18next';

const Langs = ({ config }) => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      {config.langs && (
        <div className='tabs'>
          <>
            {config.langs.map((lang, index) => {
              return (
                <a
                  onClick={() => i18n.changeLanguage(lang.val)}
                  className={i18n.language === lang.val ? 'tab tab-active' : 'tab'}>
                  {lang.name}
                </a>
              );
            })}
          </>
        </div>
      )}
    </div>
  );
};

export default Langs;
