import React, { createRef } from 'react';
import * as Constants from '../constants';
import { useTranslation } from 'react-i18next';

import FileOpenIcon from '@mui/icons-material/FileOpen';

const FileUploaderBody = ({ array, eventLocal, setEventLocal }) => {
  const { t, i18n } = useTranslation();
  const fileInput = createRef();
  const [status, setStatus] = React.useState({
    pic: false,
    st: false,
  });

  const onDelete = () => {
    if (array) {
      setEventLocal({
        ...eventLocal,
        gallery: [],
      });
    } else {
      setEventLocal({ ...eventLocal, pic: false });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.set('avatar', fileInput.current.files[0]);

    try {
      const response = await fetch(Constants.URL + '/profile', {
        method: 'POST',
        body: formData,
      });

      const parcedResponce = await response.json();

      if (response.ok) {
        setStatus({
          pic: parcedResponce.link,
          st: 'success',
        });
        console.log(parcedResponce.link);
        if (array) {
          setEventLocal({
            ...eventLocal,
            gallery: [...eventLocal.gallery, parcedResponce.link],
          });
        } else {
          setEventLocal({ ...eventLocal, pic: parcedResponce.link });
        }
      } else {
        setStatus({
          st: 'error',
        });
      }
    } catch (e) {
      setStatus({
        st: 'error',
      });
      //console.error('er', e);
    }
  };

  return (
    <div>
      <div className=''>
        <div className='card-body grid p-4'>
          {status.st && (
            <div className={status.st === 'success' ? 'badge badge-success' : 'badge badge-error'}>
              {status.st}
            </div>
          )}

          <form>
            <div className='card-actions flex flex-col justify-center'>
              <input
                type='file'
                className='file-input file-input-bordered file-input-xs file-input-outline file-input-info w-full'
                name='avatar'
                ref={fileInput}></input>
            </div>
          </form>
          <div class='grid gap-2 grid-cols-2'>
            <button
              onClick={onSubmit}
              className='btn btn-outline btn-info btn-xs w-full'
              type='submit'>
              Загрузить фото
            </button>
            <button onClick={onDelete} className='btn btn-outline btn-error btn-xs w-full'>
              Удалить фото
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileUploaderBody;
