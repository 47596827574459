import React, { useState, useEffect, useRef, useCallback } from 'react';
import Gallery from '../items/Gallery';

import { useTranslation } from 'react-i18next';

const PicTextEventBody = ({ event, options }) => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <p class='text-center whitespace-pre-wrap text-gray-700 font-bold pb-2'>
        {event.topic[i18n.language]}
      </p>
      {event.pic && (
        <div class='flex items-center justify-center'>
          <img class={options.img} src={event.pic} alt='My Image' />
        </div>
      )}
      <p class='text-left whitespace-pre-wrap m-2 text-gray-600 pb-2 truncate'>
        {event.text[i18n.language]}
      </p>

      <Gallery event={event}></Gallery>
    </div>
  );
};

export default PicTextEventBody;
