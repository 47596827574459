import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FileUploaderBody from '../items/FileUploader';
import Gallery from '../items/Gallery';

import LangsSelector from '../items/LangsSelector.js';

const HelpEditor = ({ eventLocal, setEventLocal, config }) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <LangsSelector config={config}></LangsSelector>

      {/* Редактируем заголовок*/}

      <input
        class='h-8 w-full content-center mt-2 bg-gray-50 focus:bg-white border-2 border-green-400 text-gray-700 leading-tight focus:outline-none text-center'
        onChange={(e) =>
          setEventLocal({
            ...eventLocal,
            topic: { ...eventLocal.topic, [i18n.language]: e.target.value },
          })
        }
        value={eventLocal.topic[i18n.language]}></input>

      {/* Отображаем картинку обложки*/}

      {eventLocal.pic && (
        <figure>
          <img src={eventLocal.pic} alt='Shoes' />
        </figure>
      )}

      {/* Загрузчик картинки обложки*/}
      <FileUploaderBody
        array={false}
        eventLocal={eventLocal}
        setEventLocal={setEventLocal}></FileUploaderBody>

      {/*Редактируем основной текст*/}

      <textarea
        class='h-40 w-full content-center mt-2 p-2 bg-gray-50 focus:bg-white border-2 border-green-400 text-gray-700 leading-tight focus:outline-none text-left'
        onChange={(e) =>
          setEventLocal({
            ...eventLocal,
            text: { ...eventLocal.text, [i18n.language]: e.target.value },
          })
        }
        value={eventLocal.text[i18n.language]}></textarea>

      {/*отображаем галерею*/}

      <Gallery event={eventLocal}></Gallery>

      {/* Загрузчик картинок галереи*/}
      <FileUploaderBody
        array={true}
        eventLocal={eventLocal}
        setEventLocal={setEventLocal}></FileUploaderBody>
    </>
  );
};

export default HelpEditor;
