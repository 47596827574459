import React from 'react';
//https://www.creative-tim.com/learning-lab/tailwind-starter-kit/documentation/javascript/modals/regular

const ModalWithCard = ({ children, setShowModal }) => {
  return (
    <>
      <>
        {/*justify-center items-center*/}
        <div class='flex overflow-x-hidden overflow-y-scroll fixed inset-0 z-50 outline-none focus:outline-none'>
          {/*max-w-3xl - настройка ширины*/}
          <div class='relative w-11/12 lg:w-1/2 my-2 mx-auto'>
            {/*content*/}
            <div class='border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none'>
              {/*header*/}
              <div class='flex items-end justify-end p-2 border-b border-solid border-slate-200 rounded-t'>
                <button
                  type='button'
                  onClick={() => setShowModal(false)}
                  class='bg-white rounded-md p-2 inline-flex text-gray-400 hover:text-red-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-300'>
                  <svg
                    class='h-5 w-5'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    stroke='currentColor'
                    aria-hidden='true'>
                    <path
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      d='M6 18L18 6M6 6l12 12'
                    />
                  </svg>
                </button>
              </div>
              {/*body*/}
              <div class='relative px-4 sm:px-4 lg:px-5 xl:px-8 2xl:px-10 py-5 flex-auto'>
                <div>{children}</div>
              </div>
            </div>
          </div>
        </div>
        <div class='opacity-25 fixed inset-0 z-40 bg-black'></div>
      </>
    </>
  );
};

export default ModalWithCard;
