import * as React from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import * as Constants from '../constants';
import { useNavigate } from 'react-router-dom';
import BtnStatusError from '../items/BtnStatusError.js';

//https://www.codeconcisely.com/posts/tailwind-css-dynamic-class/ классы tailwind
//https://betterprogramming.pub/how-to-authentication-users-with-token-in-a-react-application-f99997c2ee9d - авторизация с помощью токенов со стороны react

//эта страница должна делать get запрос с токеном на который должны прилетать логин и пароль
// если пользователь не залогинизировался

const Login = ({ saveToken }) => {
  const [user, setUser] = React.useState({});
  const { t, i18n } = useTranslation();

  const onSuccess = async (data) => {
    saveToken(data);
  };

  const onError = async (data) => {
    if (data[0].msg === 'user_email_not_conf') {
      console.log('отправим email еще раз');
    }
  };

  return (
    <div class='flex h-screen m-2 md:m-4 lg:m-8 items-start justify-center'>
      <div class='w-full max-w-lg'>
        <form class='bg-white shadow-2xl rounded-xl px-8 pt-6 pb-8 mb-4'>
          <div class='mb-4'>
            <label class='block text-gray-700 text-sm font-bold mb-2' for='username'>
              {t('enterLogin')}
            </label>
            <input
              value={user.username}
              onChange={(e) => setUser({ ...user, username: e.target.value })}
              class='shadow appearance-none border rounded w-full h-10 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
              id='username'
              type='text'
              placeholder={'someone@example.com'}></input>
          </div>
          <div class='mb-4'>
            <label class='block text-gray-700 text-sm font-bold mb-2' for='password'>
              {t('enterPass')}
            </label>
            <input
              onChange={(e) => setUser({ ...user, password: e.target.value })}
              class='shadow appearance-none border rounded w-full h-10 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline'
              id='password'
              type='password'
              placeholder='**********'></input>

            <BtnStatusError
              onSuccess={onSuccess}
              onError={onError}
              params={{
                url: '/auth/login',
                data: user,
                text: 'login',
                success: 'ok_success_login',
                navigate: '/user',
                refresh: true,
              }}></BtnStatusError>
          </div>
        </form>
        <p class='text-center text-gray-500 text-xs'>&copy;{t('copywrite')}</p>
      </div>
    </div>
  );
};

export default Login;
