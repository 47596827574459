import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import * as Constants from '../constants';

const CoreEditorBody = ({ getToken, icons }) => {
  const { t, i18n } = useTranslation();

  const [layout, setLayout] = React.useState([]);

  useEffect(() => {
    const get = async () => {
      await getCoreData();
    };
    get();
  }, []);

  //запрос данных разметки
  const getCoreData = async () => {
    const token = getToken();
    if (token) {
      const bearer = { headers: { Authorization: 'Bearer ' + token } };
      await axios
        .get(Constants.URL + '/layout/get', bearer)
        .then((res) => {
          setLayout(res.data);
        })
        .catch((err) => console.log(err?.response?.data?.message));
    } else {
      console.log('User not login');
    }
  };

  return (
    <>
      {layout ? (
        <div className='overflow-x-auto'>
          <table className='table'>
            {/* head */}
            <thead>
              <tr>
                <th>Номер</th>
                <th>Название</th>
                <th>Тип</th>
                <th>Ссылка</th>
                <th>Иконка</th>
                <th>Коллекция</th>
                <th>Вид</th>
                <th>Сортировка</th>
                <th>Фильтр</th>
                <th>Типы карт</th>
                <th>Добавление</th>
                <th>Подписка</th>
              </tr>
            </thead>
            <tbody>
              {/* rows */}

              {layout.map((item, cIndex) => (
                <>
                  <tr>
                    <th>{item.sort}</th>
                    <td>{item.title[i18n.language]}</td>
                    <td>{item.type}</td>
                    <td>{item.link}</td>
                    <td>{React.createElement(icons[item.icon])}</td>
                    {item.type === 'MenuItem' && (
                      <>
                        <td>{item.settings.urlprefix}</td>
                        <td>{item.settings.colsnum}</td>
                        <td>{JSON.stringify(item.settings.query?.sort)}</td>
                        <td>{JSON.stringify(item.settings.query?.filter)}</td>
                        <td>
                          {Object.keys(item.settings.events).map((key, i) => (
                            <>{item.settings.events[key] && <p>{key}</p>}</>
                          ))}
                        </td>
                        <td>{item.settings.addPanel ? 'yes' : 'no'}</td>
                        <td>{item.settings.subscription ? 'yes' : 'no'}</td>
                      </>
                    )}
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div class='flex items-center justify-center'>
          <span className='loading loading-dots loading-lg'></span>
        </div>
      )}
    </>
  );
};

export default CoreEditorBody;
