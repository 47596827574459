import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as Constants from '../constants';
import ModalWaiting from '../items/ModalWaiting.js';
import axios from 'axios';

const NewsjackingBtn = ({ eventLocal, getToken, settings }) => {
  const [status, setStatus] = React.useState(false);

  const send = async (postData) => {
    setStatus(true);
    postData.urlprefix = settings.urlprefix;
    const token = getToken();
    if (token) {
      const bearer = { headers: { Authorization: 'Bearer ' + token } };
      await axios
        .post(Constants.URL + '/newsjacking', postData, bearer)
        .then((res) => {
          setStatus(false);
          console.log(res.data);
        })
        .catch((err) => {
          setStatus(false);
          console.log(err);
        });
    } else {
      console.log('User not login');
    }
  };

  return (
    <>
      {status && <ModalWaiting>Рассылка в процессе...</ModalWaiting>}
      <button
        onClick={() => send(eventLocal)}
        className='btn btn-error btn-outline btn-sm w-full mt-10'>
        {'Разослать новость всем подписанным'}
      </button>
    </>
  );
};

export default NewsjackingBtn;
