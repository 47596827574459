import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import * as Constants from '../constants';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const UsersListBody = ({ getToken }) => {
  const { t, i18n } = useTranslation();
  const [usersdata, setUsersdata] = React.useState(null);

  useEffect(() => {
    console.log('Users List mounted!');
    const get = async () => {
      await getUsersData();
    };
    get();
  }, []);

  //запрос данных пользователя для отображения
  const getUsersData = async () => {
    const token = getToken();
    if (token) {
      const bearer = { headers: { Authorization: 'Bearer ' + token } };
      await axios
        .get(Constants.URL + '/users', bearer)
        .then((res) => {
          setUsersdata(res.data);
        })
        .catch((err) => console.log(err?.response?.data?.message));
    } else {
      console.log('User not login');
    }
  };

  return (
    <>
      {usersdata ? (
        <div className='overflow-x-auto'>
          <table className='table'>
            {/* head */}
            <thead>
              <tr>
                <th>Имя</th>
                <th>Email</th>
                <th>Роли</th>
                <th>Подтверждение</th>
              </tr>
            </thead>
            <tbody>
              {/* rows */}

              {usersdata.map((user, cIndex) => (
                <>
                  <tr>
                    <td>{user.fullname}</td>
                    <td>{user.username}</td>
                    <td>
                      {user.roles && (
                        <>
                          {user.roles.map((role, i) => (
                            <>
                              <p className='badge badge-accent'>{t(role)}</p>
                            </>
                          ))}
                        </>
                      )}
                    </td>
                    <td>
                      {user.conf ? (
                        <>
                          <CheckIcon class='fill-green-500 h-6 w-6'></CheckIcon>
                        </>
                      ) : (
                        <>
                          <CloseIcon class='fill-red-500 h-6 w-6'></CloseIcon>
                        </>
                      )}
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div class='flex items-center justify-center'>
          <span className='loading loading-dots loading-lg'></span>
        </div>
      )}
    </>
  );
};

export default UsersListBody;
