import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Constants from '../constants';

const StatusOk = ({}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  return (
    <div class='flex h-screen m-2 md:m-4 lg:m-8 items-start justify-center'>
      <div class='w-full max-w-lg'>
        <form class='bg-white shadow-2xl rounded-xl px-8 pt-6 pb-8 mb-4'>
          <div class='mb-4'>
            <label class='block text-green-500 text-l font-bold mb-2 text-center' for='username'>
              {t('reg_ok')}
            </label>
          </div>
          <p class='text-center'>{t('subs_warn')}</p>
          <p class='text-center mt-8 text-blue-600'>
            <button
              onClick={() => {
                let url;
                if (Constants.URL_PREFIX) {
                  url = '/' + Constants.URL_PREFIX + '/login';
                } else {
                  url = Constants.URL_PREFIX + '/login';
                }
                navigate(url);
              }}>
              {t('enter_link')}
            </button>
          </p>
        </form>
        <p class='text-center text-gray-500 text-xs'>&copy;{t('copywrite')}</p>
      </div>
    </div>
  );
};

export default StatusOk;
