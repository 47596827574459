import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as Constants from '../constants';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Btn = ({ params, onSuccess, onError }) => {
  const { t, i18n } = useTranslation();
  const [load, setLoad] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const navigate = useNavigate();

  const send = async (postData) => {
    setLoad(true);
    let bearer = {};
    if (params.token) {
      bearer = { headers: { Authorization: 'Bearer ' + params.token } };
    }

    await axios
      .post(Constants.URL + params.url, postData, bearer)
      .then((res) => {
        setLoad(false);
        console.log(res.data);
        setErrors([{ msg: t(params.success) }]);
        if (onSuccess) onSuccess(res.data.message);
        let url;
        if (Constants.URL_PREFIX) {
          url = '/' + Constants.URL_PREFIX + params.navigate;
        } else {
          url = params.navigate;
        }
        if (params.navigate) navigate(url);
        if (params.refresh) navigate(0);
      })
      .catch((err) => {
        setLoad(false);
        const msg = err?.response?.data?.message;
        if (msg) {
          setErrors(msg);
        } else {
          msg = [{ msg: t('server_not_reply') }];
          setErrors(msg);
        }
        if (onError) onError(msg);
      });
  };

  return (
    <>
      {errors.map((error, index) => {
        return (
          <p key={index} class='mt-2 text-red-500 p-0 font-bold text-xs italic'>
            {t(error.msg)}
          </p>
        );
      })}

      <button
        disabled={load}
        onClick={() => send(params.data)}
        type='button'
        className='btn w-full mt-4 bg-blue-100 hover:bg-blue-200 text-gray-500 font-bold'>
        {load && <span className='loading loading-spinner'></span>}
        {!load && <>{t(params.text)}</>}
      </button>
    </>
  );
};

export default Btn;
