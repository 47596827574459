import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as Constants from '../constants';
import ModalWaiting from './ModalWaiting.js';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const EmailSendBtn = ({ data, getToken }) => {
  const { t, i18n } = useTranslation();
  const [status, setStatus] = React.useState(false);
  const [errors, setErrors] = React.useState([]);

  const send = async (postData) => {
    setStatus(true);
    const token = getToken();
    if (token) {
      const bearer = { headers: { Authorization: 'Bearer ' + token } };
      await axios
        .post(Constants.URL + '/email', postData, bearer)
        .then((res) => {
          setStatus(false);
          console.log(res.data);
          setErrors([{ msg: t('ok_request_sent') }]);
        })
        .catch((err) => {
          setStatus(false);
          const msg = err?.response?.data?.message;
          if (msg) {
            setErrors(msg);
          }
        });
    } else {
      console.log('User not login');
    }
  };

  return (
    <>
      {errors.map((error, index) => {
        return (
          <p key={index} class='text-red-500 mb-0 p-0 font-bold text-xs italic'>
            {t(error.msg)}
          </p>
        );
      })}
      {status && <ModalWaiting>Отправка...</ModalWaiting>}
      <button onClick={() => send(data)} className='btn btn-error btn-outline btn-sm mt-3 w-full'>
        {t('send')}
      </button>
    </>
  );
};

export default EmailSendBtn;
