import React, { useState, useEffect, useRef, useCallback } from 'react';
import Gallery from '../items/Gallery';

import { useTranslation } from 'react-i18next';

const HtmlHelpEventBody = ({ event }) => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div>
        {!event.helped ? (
          <div>
            <div class='flex mb-2 h-6 items-center'>
              <div class='w-8/12'>
                <label class='pr-4 text-gray-500 font-bold'>{t('required')}</label>
              </div>
              <div class='flex justify-end w-4/12'>
                <p class='pr-4 text-gray-700 font-bold'>{event.required + ' EUR'}</p>
              </div>
            </div>
            <div class='flex mb-2 h-6 items-center'>
              <div class='w-8/12'>
                <label class='pr-4 text-gray-500 font-bold'>{t('collected')}</label>
              </div>
              <div class='flex justify-end w-4/12'>
                <p class='pr-4 text-gray-700 font-bold'>{event.collected + ' EUR'}</p>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div class='flex mb-2 h-6 items-center'>
              <div class='w-8/12'>
                <label class='pr-4 text-green-500 font-bold'>{t('helped')}</label>
              </div>
              <div class='flex justify-end w-4/12'>
                <p class='pr-4 text-gray-700 font-bold'>{event.required + ' EUR'}</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        <p class='text-center whitespace-pre-wrap text-gray-700 font-bold pb-2'>
          {event.topic[i18n.language]}
        </p>
        {event.pic && (
          <figure>
            <img class='object-cover' src={event.pic} alt='My Image' />
          </figure>
        )}
        <p class='text-left whitespace-pre-wrap m-2 text-gray-600 pb-2 truncate'>
          {event.text[i18n.language]}
        </p>
        <Gallery event={event}></Gallery>
      </div>
    </div>
  );
};

export default HtmlHelpEventBody;
