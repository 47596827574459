/*
   http://nikolsoborsocial.org/
   created by Dmitry Borisenko
   Vienna, Austria 1030, Juchgasse 5/17
   +43 67761588253
  */

//https://blog.openreplay.com/simple-sidebars-with-react-pro-sidebar-and-material-ui/ - шаблон приложения
//https://www.npmjs.com/package/@randumbwilliam/react-pro-sidebar - более продвинутый sidebar
//https://www.npmjs.com/package/react-pro-sidebar - обычный
//https://stackoverflow.com/questions/39435395/reactjs-how-to-determine-if-the-application-is-being-viewed-on-mobile-or-deskto

import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';

import NewspaperIcon from '@mui/icons-material/Newspaper';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import ChurchIcon from '@mui/icons-material/Church';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LoginIcon from '@mui/icons-material/Login';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PeopleIcon from '@mui/icons-material/People';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import SchoolIcon from '@mui/icons-material/School';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import GroupIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PanoramaIcon from '@mui/icons-material/Panorama';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

//https://dev-gang.ru/article/kak-dobavit-podderzhku-neskolkih-jazykov-v-reactjs-f9qkxmmga9/ - добавление языков
import { useTranslation } from 'react-i18next';

//https://github.com/azouaoui-med/react-pro-sidebar - api sidebar
//https://blog.logrocket.com/creating-responsive-sidebar-react-pro-sidebar-mui/
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from 'react-pro-sidebar';

import Events from './pages/Events.js';
import Login from './pages/Login.js';
import Reg from './pages/Regestration.js';
import User from './pages/User.js';
import StatusOk from './pages/StatusOk.js';
import StatusErr from './pages/StatusErr.js';

import axios from 'axios';
import { Routes, Route, Link } from 'react-router-dom';

import * as Constants from './constants';

const icons = {
  NewspaperIcon: NewspaperIcon,
  VolunteerActivismIcon: VolunteerActivismIcon,
  FavoriteIcon: FavoriteIcon,
  ReceiptRoundedIcon: ReceiptRoundedIcon,
  ChurchIcon: ChurchIcon,
  PermContactCalendarIcon: PermContactCalendarIcon,
  MenuRoundedIcon: MenuRoundedIcon,
  AccountCircleIcon: AccountCircleIcon,
  LoginIcon: LoginIcon,
  HowToRegIcon: HowToRegIcon,
  PeopleIcon: PeopleIcon,
  IconButton: IconButton,
  InfoIcon: InfoIcon,
  LibraryBooksIcon: LibraryBooksIcon,
  ContactMailIcon: ContactMailIcon,
  SchoolIcon: SchoolIcon,
  LocalGroceryStoreIcon: LocalGroceryStoreIcon,
  BuildCircleIcon: BuildCircleIcon,
  GroupIcon: GroupIcon,
  SettingsIcon: SettingsIcon,
  NotificationsIcon: NotificationsIcon,
  PanoramaIcon: PanoramaIcon,
  NotificationsActiveIcon: NotificationsActiveIcon,
};

const App = () => {
  const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } = useProSidebar();
  const { t, i18n } = useTranslation();

  const [layout, setLayout] = React.useState([]);
  const [config, setConfig] = React.useState({});
  const [userdata, setUserdata] = React.useState(null);

  const saveToken = (userToken) => {
    sessionStorage.setItem('token', JSON.stringify(userToken));
    console.log('Token saved: ', userToken);
  };

  const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken;
  };

  useEffect(() => {
    console.log('App mounted!');
    const get = async () => {
      await getConfig();
      await getLayout();
      await getUserData();
    };
    get();
  }, []);

  //запрос конфигурации портала
  const getConfig = async () => {
    await axios
      .get(Constants.URL + '/config/get')
      .then((res) => {
        setConfig(res.data);
      })
      .catch((err) => console.log(err?.response?.data?.message));
  };

  //запрос разметки бокового меню
  const getLayout = async () => {
    await axios
      .get(Constants.URL + '/layout/get')
      .then((res) => {
        setLayout(res.data);
        console.log('layout:', layout);
      })
      .catch((err) => console.log(err?.response?.data?.message));
  };

  //запрос данных пользователя для отображения
  const getUserData = async () => {
    const token = getToken();
    if (token) {
      const bearer = { headers: { Authorization: 'Bearer ' + token } };
      await axios
        .get(Constants.URL + '/user/email', bearer)
        .then((res) => {
          setUserdata(res.data);
        })
        .catch((err) => console.log(err?.response?.data?.message));
    } else {
      console.log('User not login');
    }
  };

  return (
    <div class='flex h-screen'>
      <div class='flex select-none'>
        <Sidebar width='280px' className='sidebar-stl' breakPoint='sm'>
          <Menu>
            <div class='mt-2 mb-8'>
              <MenuItem onClick={() => collapseSidebar()} icon={<MenuRoundedIcon />}>
                {t('welcome')}
              </MenuItem>
            </div>

            {/*динамическая часть бокового меню*/}

            {layout && (
              <div>
                {layout.map((item, index) => {
                  {
                    {
                      /*если это не вложенный пункт меню*/
                    }
                    if (item.type === 'MenuItem' && !item.refersto) {
                      return (
                        <MenuItem
                          onClick={() => {
                            if (broken) toggleSidebar();
                          }}
                          key={index}
                          component={
                            <Link to={Constants.URL_PREFIX + '/' + item.link} className='link' />
                          }
                          icon={React.createElement(icons[item.icon])}>
                          {item.title[i18n.language]}
                        </MenuItem>
                      );
                      {
                        /*если это вложенный пункт меню*/
                      }
                    } else if (item.type === 'SubMenu') {
                      return (
                        <SubMenu
                          label={item.title[i18n.language]}
                          icon={React.createElement(icons[item.icon])}>
                          {layout.map((subitem, subindex) => {
                            {
                              /*вложение в этот пункт подпунктов */
                            }
                            if (subitem.refersto === item.subname) {
                              return (
                                <MenuItem
                                  onClick={() => {
                                    if (broken) toggleSidebar();
                                  }}
                                  key={index + subindex}
                                  component={
                                    <Link
                                      to={Constants.URL_PREFIX + '/' + subitem.link}
                                      className='link'
                                    />
                                  }
                                  icon={React.createElement(icons[subitem.icon])}>
                                  {subitem.title[i18n.language]}
                                </MenuItem>
                              );
                            }
                          })}
                        </SubMenu>
                      );
                    }
                  }
                })}
              </div>
            )}

            <SubMenu label={t('users')} icon={<PeopleIcon />}>
              <MenuItem
                onClick={() => {
                  if (broken) toggleSidebar();
                }}
                component={<Link to={Constants.URL_PREFIX + '/' + 'login'} className='link' />}
                icon={<LoginIcon />}>
                {t('login')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  if (broken) toggleSidebar();
                }}
                component={
                  <Link to={Constants.URL_PREFIX + '/' + 'regestration'} className='link' />
                }
                icon={<HowToRegIcon />}>
                {t('reg')}
              </MenuItem>
            </SubMenu>

            {userdata && (
              <MenuItem
                onClick={() => {
                  if (broken) toggleSidebar();
                }}
                component={<Link to={Constants.URL_PREFIX + '/' + 'user'} className='link' />}
                icon={<AccountCircleIcon />}>
                {userdata.username}
              </MenuItem>
            )}
          </Menu>

          <div class='flex justify-start items-end'>
            {config.langs && (
              <select
                value={i18n.language}
                onChange={(e) => i18n.changeLanguage(e.target.value)}
                class='w-14 ml-4 my-2 p-1 text-sm text-gray-900 border border-gray-300 rounded-sm bg-gray-50 focus:ring-blue-500 focus:border-blue-500'>
                <>
                  {config.langs.map((lang, index) => {
                    return (
                      <option key={index} value={lang.val}>
                        {lang.val}
                      </option>
                    );
                  })}
                </>
              </select>
            )}
          </div>
        </Sidebar>
      </div>

      <div class='w-full overflow-y-scroll bg-gray-50'>
        {broken && (
          <div class='sticky top-0 m-0 w-full bg-[#80aac285] shadow-2xl'>
            <IconButton onClick={() => toggleSidebar()}>
              <MenuRoundedIcon></MenuRoundedIcon>
            </IconButton>
          </div>
        )}

        <div>
          <section>
            <Routes>
              <Route
                path={Constants.URL_PREFIX + '/' + 'login'}
                element={<Login saveToken={saveToken} />}
              />
              <Route path={Constants.URL_PREFIX + '/' + 'regestration'} element={<Reg />} />
              <Route
                path={Constants.URL_PREFIX + '/' + 'user'}
                element={<User userdata={userdata} getToken={getToken} icons={icons} />}
              />

              <Route path={Constants.URL_PREFIX + '/' + 'statusok'} element={<StatusOk />} />
              <Route path={Constants.URL_PREFIX + '/' + 'statuserr'} element={<StatusErr />} />

              {/*динамическая часть роутера*/}
              {layout && (
                <>
                  {layout.map((item, index) => {
                    {
                      if (item.type !== 'SubMenu') {
                        return (
                          <Route
                            key={index}
                            path={Constants.URL_PREFIX + '/' + item.link}
                            element={React.createElement(Events, {
                              key: index,
                              getToken: getToken,
                              settings: item.settings,
                              config: config,
                              userdata: userdata,
                            })}
                          />
                        );
                      }
                    }
                  })}
                </>
              )}
            </Routes>
          </section>
        </div>
      </div>
    </div>
  );
};

export default App;
