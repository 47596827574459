import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

import LangsSelector from '../items/LangsSelector.js';

import { useTranslation } from 'react-i18next';

//https://stackoverflow.com/questions/66180213/how-to-prevent-css-libraries-like-tailwindcss-and-bootstrap-to-effect-wysiwyg-ed

const HtmlEditor = ({ eventLocal, setEventLocal, config }) => {
  const editorRef = useRef(null);
  const { t, i18n } = useTranslation();

  function onEditorChange(newText) {
    console.log(newText);
    let event = eventLocal;
    event.html[i18n.language] = newText;
    setEventLocal(event);
  }

  const [lang, setLang] = React.useState(i18n.language);

  return (
    <>
      <div class='w-full mb-0 items-center'>
        <>
          <LangsSelector config={config}></LangsSelector>

          <Editor
            apiKey='voyivu07uirmnu3opp2wikc20q3w5ypnehoa0jfqvhkqihfb'
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue={eventLocal.html[i18n.language]}
            onEditorChange={(newText) => onEditorChange(newText)}
            init={{
              height: 600,
              width: 'auto',
              menubar: true,
              image_advtab: true,
              plugins: [
                'advlist',
                'autolink',
                'lists',
                'link',
                'image',
                'charmap',
                'preview',
                'anchor',
                'searchreplace',
                'visualblocks',
                'code',
                'fullscreen',
                'insertdatetime',
                'media',
                'table',
                'code',
                'help',
                'wordcount',
              ],

              toolbar:
                'undo redo | blocks | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | fontfamily fontsize | help',

              content_style: 'body { font-family:Helvetica, Arial, sans-serif; font-size:12px }',
            }}
          />
        </>
      </div>
    </>
  );
};

export default HtmlEditor;
