import React, { useState, useEffect, useRef, useCallback, createRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Constants from '../constants';

import axios from 'axios';

import UserProfileBody from '../usermenu/UserProfileBody.js';
import FileUploaderBody from '../usermenu/FileUploaderBody.js';
import UsersListBody from '../usermenu/UsersListBody.js';
import CoreEditorBody from '../usermenu/CoreEditorBody.js';
import SubscriptionBody from '../usermenu/SubscriptionBody.js';

const User = ({ userdata, getToken, icons }) => {
  const { t, i18n } = useTranslation();
  const [tab, setTab] = React.useState('user');
  const [edit, setEdit] = React.useState(false);

  useEffect(() => {
    const get = async () => {
      await editCoreReq();
    };
    get();
  }, []);

  const editCoreReq = async () => {
    const token = getToken();
    if (token) {
      const bearer = { headers: { Authorization: 'Bearer ' + token } };
      await axios
        .get(Constants.URL + '/core/edit/request', bearer)
        .then((res) => {
          setEdit(true);
          console.log('users ', res.data);
        })
        .catch((err) => console.log(err?.response?.data?.message));
    } else {
      console.log('User not login');
    }
  };

  return (
    <div class='flex h-screen m-2 md:m-4 lg:m-8  items-start justify-center'>
      <div class='flex flex-col w-full md:w-11/12 lg:w-10/12'>
        <div className='tabs w-full'>
          <a
            onClick={() => setTab('user')}
            className={
              tab === 'user' ? 'tab tab-lifted tab-active flex-auto' : 'tab tab-lifted flex-auto'
            }>
            {React.createElement(icons.AccountCircleIcon)}
          </a>
          <a
            onClick={() => setTab('subs')}
            className={
              tab === 'subs' ? 'tab tab-lifted tab-active flex-auto' : 'tab tab-lifted flex-auto'
            }>
            {React.createElement(icons.NotificationsIcon)}
          </a>
          {edit && (
            <>
              <a
                onClick={() => setTab('tools')}
                className={
                  tab === 'tools'
                    ? 'tab tab-lifted tab-active flex-auto'
                    : 'tab tab-lifted flex-auto'
                }>
                {React.createElement(icons.PanoramaIcon)}
              </a>
              <a
                onClick={() => setTab('users')}
                className={
                  tab === 'users'
                    ? 'tab tab-lifted tab-active flex-auto'
                    : 'tab tab-lifted flex-auto'
                }>
                {React.createElement(icons.GroupIcon)}
              </a>
              <a
                onClick={() => setTab('core')}
                className={
                  tab === 'core'
                    ? 'tab tab-lifted tab-active flex-auto'
                    : 'tab tab-lifted flex-auto'
                }>
                {React.createElement(icons.SettingsIcon)}
              </a>
            </>
          )}
        </div>

        <div className='card w-full rounded-xl rounded-t-none p-8 border border-gray-200 border-t-0 shadow-gray-200 bg-white shadow-2xl'>
          {tab === 'user' && userdata && <UserProfileBody userdata={userdata}></UserProfileBody>}
          {tab === 'subs' && (
            <SubscriptionBody getToken={getToken} icons={icons}></SubscriptionBody>
          )}
          {tab === 'tools' && <FileUploaderBody></FileUploaderBody>}
          {tab === 'users' && <UsersListBody getToken={getToken}></UsersListBody>}
          {tab === 'core' && <CoreEditorBody getToken={getToken} icons={icons}></CoreEditorBody>}
        </div>
      </div>
    </div>
  );
};

export default User;
