import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

const HelpEditor = ({ eventLocal, setEventLocal, config }) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div class='flex-wrap mb-2 items-center'>
        <div class='flex mb-1 h-6 items-center'>
          <div class='w-2/3'>
            <label class=' text-gray-500 text-sm font-bold'>{t('required')}</label>
          </div>
          <div class='flex justify-end w-1/3'>
            <input
              class='h-6 content-center mt-1 bg-gray-50 focus:bg-white border-2 border-gray-100 text-gray-700 leading-tight focus:outline-none text-center focus:border-indigo-500'
              onChange={(e) =>
                setEventLocal({ ...eventLocal, required: JSON.parse(e.target.value) })
              }
              type='number'
              min='0'
              step='1'
              value={eventLocal.required}></input>
          </div>
        </div>

        <div class='flex mb-1 h-6 items-center'>
          <div class='w-2/3'>
            <label class=' text-gray-500 text-sm font-bold'>{t('collected')}</label>
          </div>
          <div class='flex justify-end w-1/3'>
            <input
              class='h-6 content-center mt-1 bg-gray-50 focus:bg-white border-2 border-gray-100 text-gray-700 leading-tight focus:outline-none text-center focus:border-indigo-500'
              onChange={(e) =>
                setEventLocal({ ...eventLocal, collected: JSON.parse(e.target.value) })
              }
              type='number'
              min='0'
              step='1'
              value={eventLocal.collected}></input>
          </div>
        </div>

        <div class='flex mb-2 h-6 items-center'>
          <div class='w-2/3'>
            <label class=' text-gray-500 text-sm font-bold'>{t('helped')}</label>
          </div>
          <div class='flex justify-center w-1/3'>
            <input
              checked={eventLocal.helped}
              onChange={(e) => setEventLocal({ ...eventLocal, helped: e.target.checked })}
              type='checkbox'
              class='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500'></input>
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpEditor;
