import * as React from 'react';
import { useTranslation } from 'react-i18next';

const StatusOk = ({}) => {
  const { t, i18n } = useTranslation();

  return (
    <div class='flex h-screen m-2 md:m-4 lg:m-8 items-start justify-center'>
      <div class='w-full max-w-lg'>
        <form class='bg-white shadow-2xl rounded-xl px-8 pt-6 pb-8 mb-4'>
          <div class='mb-4'>
            <label class='block text-red-500 text-l font-bold mb-2 text-center' for='username'>
              {t('reg_err')}
            </label>
          </div>
        </form>
        <p class='text-center text-gray-500 text-xs'>&copy;{t('copywrite')}</p>
      </div>
    </div>
  );
};

export default StatusOk;
