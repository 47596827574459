import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const Declaration = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div class='no-tailwindcss-base' dangerouslySetInnerHTML={{ __html: t('decl') }}></div>
    </>
  );
};

export default Declaration;
