import React, { createRef } from 'react';
import * as Constants from '../constants';
import { useTranslation } from 'react-i18next';

const FileUploaderBody = ({}) => {
  const { t, i18n } = useTranslation();
  const fileInput = createRef();
  const [status, setStatus] = React.useState({
    pic: false,
    st: false,
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.set('avatar', fileInput.current.files[0]);

    try {
      const response = await fetch(Constants.URL + '/profile', {
        method: 'POST',
        body: formData,
      });

      const parcedResponce = await response.json();

      if (response.ok) {
        setStatus({
          pic: parcedResponce.link,
          st: 'success',
        });
        console.log(parcedResponce.link);
      } else {
        setStatus({
          st: 'error',
        });
      }
    } catch (e) {
      setStatus({
        st: 'error',
      });
      //console.error('er', e);
    }
  };

  return (
    <div class='grid grid-cols-1 gap-6 lg:grid-cols-2'>
      <div>
        {status.pic && (
          <figure>
            <img src={status.pic} alt='Shoes' />
          </figure>
        )}
        <div className='card-body'>
          {status.st && (
            <div className={status.st === 'success' ? 'badge badge-success' : 'badge badge-error'}>
              {status.st}
            </div>
          )}

          <form>
            <div className='card-actions flex flex-col justify-center'>
              {status.pic && (
                <input
                  type='text'
                  value={status.pic}
                  placeholder=''
                  className='input input-bordered input-sm w-full'
                />
              )}
              <input
                type='file'
                className='file-input file-input-bordered file-input-sm file-input-info w-full'
                name='avatar'
                ref={fileInput}></input>
              <button onClick={onSubmit} className='btn btn-info btn-sm w-full' type='submit'>
                Загрузить фото
              </button>
            </div>
          </form>
        </div>
      </div>
      <div>
        <div class='flex m-8 items-start justify-center'>
          <p class='text-left'>Здесь можно загрузить фотографии на сервер и скопировать ссылку</p>
        </div>
      </div>
    </div>
  );
};

export default FileUploaderBody;
