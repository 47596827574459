import React from 'react';
//https://www.creative-tim.com/learning-lab/tailwind-starter-kit/documentation/javascript/modals/regular

const ModalWaiting = ({ children }) => {
  return (
    <>
      <>
        {/*justify-center items-center*/}
        <div class='flex overflow-x-hidden overflow-y-scroll justify-center items-center fixed inset-0 z-50 outline-none focus:outline-none'>
          {/*max-w-3xl - настройка ширины*/}
          <div class='relative w-2/6 my-2 mx-auto'>
            {/*content*/}
            <div class='border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none justify-center items-center'>
              {/*body*/}
              <div class='relative p-2 flex-auto'>
                <div>
                  <div class='flex justify-center items-center'>
                    <span className='loading loading-spinner loading-sm mr-3'></span>
                    <p className='text-center'>{children}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='opacity-25 fixed inset-0 z-40 bg-black'></div>
      </>
    </>
  );
};

export default ModalWaiting;
