import React, { useState, useEffect, useRef, useCallback } from 'react';

const Gallery = ({ event }) => {
  return (
    <div>
      {event.gallery?.length > 0 && (
        <div class='container mx-auto p-2'>
          <div class='m-1 flex flex-wrap md:-m-2'>
            {event.gallery.map && (
              <>
                {event.gallery.map((link, Index) => (
                  <div key={Index} class='flex w-1/3 flex-wrap'>
                    <div class='w-full p-1 md:p-2'>
                      <img
                        alt='gallery'
                        class='block h-full w-full rounded-lg object-cover object-center'
                        src={link}
                      />
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
