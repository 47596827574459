import React, { useState, useEffect, useRef, useCallback } from 'react';

import { useTranslation } from 'react-i18next';

//remove-all
//no-tailwindcss
//no-tailwindcss-base
//https://stackoverflow.com/questions/61039259/disable-tailwind-on-a-div-or-component
//prose prose-lg

const HtmlJsEventBody = ({ event }) => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div>
        <div
          class='no-tailwindcss-base'
          dangerouslySetInnerHTML={{ __html: event.html[i18n.language] }}></div>
      </div>
    </div>
  );
};

export default HtmlJsEventBody;
