import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const UserProfileBody = ({ userdata }) => {
  const { t, i18n } = useTranslation();
  return (
    <div class='grid grid-cols-1 gap-6 lg:grid-cols-12 '>
      <div class='col-span-3'>
        <div className='avatar'>
          <div className='h-25 rounded'>
            <img src='/avatar.png' />
          </div>
        </div>
      </div>
      <div class='col-span-9'>
        <div class='text-center lg:text-left'>
          <h2 class='text-2xl mb-2 font-bold text-zinc-700'>{userdata.fullname}</h2>
          {/*<p class='mt-2 font-semibold text-zinc-700'>{userdata.username}</p>*/}
          <div class='flex flex-col'>
            {userdata.roles && (
              <>
                {userdata.roles.map((role, cIndex) => (
                  <p class='mt-2 text-zinc-500' key={cIndex}>
                    <div className='badge badge-accent'>{t(role)} </div>
                  </p>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfileBody;
