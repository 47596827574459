import React, { useState, useEffect, useRef, useCallback } from 'react';
import ModalWithCard from '../items/ModalWithCard.js';

import HtmlEditor from './HtmlEditor.js';
import HtmlEditorJs from './HtmlEditorJs.js';
import HelpEditor from './HelpEditor.js';
import PicTextEditor from './PicTextEditor.js';

import NewsjackingBtn from '../items/NewsjackingBtn.js';

const EventEditMenu = ({ event, saveEvent, delEvent, getToken, settings, config }) => {
  const [editingModal, showEditingModal] = React.useState(false);
  const [eventLocal, setEventLocal] = React.useState(event);

  //данный useEffect необходим для синхронизации переменных eventLocal и event при последующих ререндерингах
  useEffect(() => {
    setEventLocal(event);
  }, [event, editingModal]);

  function onSave() {
    saveEvent(eventLocal);
    showEditingModal(false);
  }
  return (
    <div>
      <div class='flex justify-start'>
        {/* отображаем меню редактирования */}
        <div className='tabs'>
          <a className='tab tab-sm' onClick={() => showEditingModal(true)}>
            Edit
          </a>
          <a className='tab tab-sm' onClick={() => delEvent(event)}>
            Delete
          </a>
        </div>
      </div>
      {/* открываем соответствующие редакоры */}
      {editingModal && (
        <ModalWithCard setShowModal={showEditingModal}>
          {event.type === 'html' && (
            <>
              <HtmlEditor
                eventLocal={eventLocal}
                setEventLocal={setEventLocal}
                config={config}></HtmlEditor>
              <button
                onClick={() => onSave()}
                className='btn btn-success btn-outline btn-sm w-full'>
                {'Сохранить'}
              </button>
            </>
          )}
          {event.type === 'htmljs' && (
            <>
              <HtmlEditorJs
                eventLocal={eventLocal}
                setEventLocal={setEventLocal}
                config={config}></HtmlEditorJs>
              <button
                onClick={() => onSave()}
                className='btn btn-success btn-outline btn-sm w-full'>
                {'Сохранить'}
              </button>
            </>
          )}
          {event.type === 'pictexthelp' && (
            <>
              <PicTextEditor
                eventLocal={eventLocal}
                setEventLocal={setEventLocal}
                config={config}></PicTextEditor>
              <HelpEditor
                eventLocal={eventLocal}
                setEventLocal={setEventLocal}
                config={config}></HelpEditor>
              <button
                onClick={() => onSave()}
                className='btn btn-success btn-outline btn-sm w-full'>
                {'Сохранить'}
              </button>
              {settings.subscription && (
                <NewsjackingBtn
                  eventLocal={eventLocal}
                  getToken={getToken}
                  settings={settings}></NewsjackingBtn>
              )}
            </>
          )}
          {event.type === 'pictext' && (
            <>
              <PicTextEditor
                eventLocal={eventLocal}
                setEventLocal={setEventLocal}
                config={config}></PicTextEditor>
              <button
                onClick={() => onSave()}
                className='btn btn-success btn-outline btn-sm w-full'>
                {'Сохранить'}
              </button>
              {settings.subscription && (
                <NewsjackingBtn
                  eventLocal={eventLocal}
                  getToken={getToken}
                  settings={settings}></NewsjackingBtn>
              )}
            </>
          )}
        </ModalWithCard>
      )}
    </div>
  );
};

export default EventEditMenu;
