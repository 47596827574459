//https://github.com/moveyourdigital/editorjs-blocks-react-renderer
//https://github.com/moveyourdigital/editorjs-blocks-react-renderer/issues/81
import React, { useState, useEffect, useRef, useCallback } from 'react';
import Blocks from 'editorjs-blocks-react-renderer';
import { useTranslation } from 'react-i18next';
import edjsParser from 'editorjs-parser';

const HtmlEventBody = ({ event, options }) => {
  const { t, i18n } = useTranslation();

  //remove-all
  //no-tailwindcss
  //no-tailwindcss-base
  //https://stackoverflow.com/questions/61039259/disable-tailwind-on-a-div-or-component
  //prose prose-lg

  return (
    <div>
      <div class='no-tailwindcss-base'>
        <Blocks
          data={event.json[i18n.language]}
          config={{
            header: {
              className: 'prose font-bold text-xl text-center',
            },
            paragraph: {
              className: 'text-base text-justify text-opacity-75',
              actionsClassNames: {
                alignment: 'text-center', // This is a substitution placeholder: left or center.
              },
            },
            table: {
              className: 'w-full mb-4 border-collapse border border-gray-300',
            },
            code: {
              className: 'language-js overflow-x-auto prose-xxs font-bold prose text-sm',
            },
            delimiter: {
              className: 'border border-2 w-16 mx-auto',
            },
            image: {
              className: options.img,
              actionsClassNames: {
                stretched: 'w-1/2 h-80 object-cover',
                withBorder: 'border border-2',
                withBackground: 'p-2',
              },
            },
            embed: {
              className:
                'aspect-w-16 aspect-h-9 w-full frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen',
            },
            list: {
              className: 'list-inside',
            },

            quote: {
              className: 'py-3 px-5 italic font-serif',
            },
          }}
        />
      </div>
    </div>
  );
};

export default HtmlEventBody;
