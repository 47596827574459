import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import * as Constants from '../constants';

import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

const SubscriptionBody = ({ getToken, icons }) => {
  const { t, i18n } = useTranslation();

  const [layout, setLayout] = React.useState([]);
  const [userdata, setUserdata] = React.useState([]);

  useEffect(() => {
    const get = async () => {
      await getUserData();
      await getCoreData();
    };
    get();
  }, []);

  //запрос данных разметки
  const getCoreData = async () => {
    const token = getToken();
    if (token) {
      const bearer = { headers: { Authorization: 'Bearer ' + token } };
      await axios
        .get(Constants.URL + '/layout/get', bearer)
        .then((res) => {
          setLayout(res.data);
        })
        .catch((err) => console.log(err?.response?.data?.message));
    } else {
      console.log('User not login');
    }
  };

  //запрос данных пользователя для отображения
  const getUserData = async () => {
    const token = getToken();
    if (token) {
      const bearer = { headers: { Authorization: 'Bearer ' + token } };
      await axios
        .get(Constants.URL + '/user/email', bearer)
        .then((res) => {
          setUserdata(res.data);
        })
        .catch((err) => console.log(err?.response?.data?.message));
    } else {
      console.log('User not login');
    }
  };

  async function disable(name) {
    setUserdata({
      ...userdata,
      subscriptions: userdata.subscriptions.filter((w) => w !== name),
    });
  }

  async function enable(name) {
    setUserdata({
      ...userdata,
      subscriptions: [...userdata.subscriptions, name],
    });
  }

  useEffect(() => {
    console.log(userdata);
    const get = async () => {
      await editOneEvent(userdata);
    };
    get();
  }, [userdata]);

  //редактирование пользователя
  const editOneEvent = async (postData) => {
    const token = getToken();
    if (token) {
      const bearer = { headers: { Authorization: 'Bearer ' + token } };
      await axios
        .post(Constants.URL + '/users/edit', postData, bearer)
        .then((res) => console.log(res.data))
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log('User not login');
    }
  };

  return (
    <>
      {userdata && layout ? (
        <div className='overflow-x-auto select-none'>
          <table className='table'>
            <tbody>
              {layout.map((item, cIndex) => (
                <>
                  {item.type === 'MenuItem' && item.settings.subscription && (
                    <tr key={cIndex}>
                      <td>{React.createElement(icons[item.icon])}</td>
                      <td>{item.title[i18n.language]}</td>

                      <td>
                        {userdata.subscriptions.includes(item.settings.urlprefix) ? (
                          <NotificationsActiveIcon
                            onClick={() => disable(item.settings.urlprefix)}
                            class='w-6 h-6 fill-green-500'></NotificationsActiveIcon>
                        ) : (
                          <NotificationsIcon
                            onClick={() => enable(item.settings.urlprefix)}
                            class='w-6 h-6 fill-gray-400'></NotificationsIcon>
                        )}
                      </td>
                    </tr>
                  )}
                </>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div class='flex items-center justify-center'>
          <span className='loading loading-dots loading-lg'></span>
        </div>
      )}
    </>
  );
};

export default SubscriptionBody;
