import React, { useState, useEffect, useRef, useCallback } from 'react';
import EventEditMenu from '../editors/EventEditMenu';
import CardForEvent from '../items/CardForEvent';
import ModalWithCard from '../items/ModalWithCard.js';
import HtmlHelpEventBody from './PicTextHelpEventBody.js';
import { useProSidebar } from 'react-pro-sidebar';

const HtmlHelp = ({ event, saveEvent, edit, delEvent, settings, getToken, config, userdata }) => {
  const { broken } = useProSidebar();
  const [showModal, setShowModal] = useState(false);

  return (
    <div class='w-full'>
      {/* Если требуется редактирование event-а*/}
      {edit && (
        <div>
          <EventEditMenu
            event={event}
            saveEvent={saveEvent}
            delEvent={delEvent}
            getToken={getToken}
            settings={settings}
            config={config}></EventEditMenu>
        </div>
      )}
      {/* Если нужно открыть в модальном окне*/}
      {!broken && showModal && settings.colsnum === 3 && (
        <ModalWithCard setShowModal={setShowModal}>
          <HtmlHelpEventBody event={event}></HtmlHelpEventBody>
        </ModalWithCard>
      )}
      {/* Карточка для event-а*/}
      <CardForEvent settings={settings} setShowModal={setShowModal}>
        <HtmlHelpEventBody event={event}></HtmlHelpEventBody>
      </CardForEvent>
    </div>
  );
};

export default HtmlHelp;
