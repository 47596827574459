import React, { useState, useEffect, useRef, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import EmailSendBtn from '../items/EmailSendBtn';

const FeedbackEventBody = ({ event, getToken, userdata }) => {
  const { t, i18n } = useTranslation();
  const [data, setData] = React.useState({});

  function onChange(val) {
    setData({
      ...data,
      index: val,
      subject: event.types[val].name[i18n.language] + '/' + userdata.fullname,
      emails: event.types[val].emails,
    });
  }

  return (
    <>
      <h3 class='mb-6 mt-4 font-bold text-xl text-center'>{t('contact_with')}</h3>
      <select
        className='select select-success w-full mb-6'
        value={data.index}
        onChange={(e) => onChange(e.target.value)}>
        <option disabled selected>
          {t('chose')}
        </option>
        {event.types && (
          <>
            {event.types.map((form, index) => {
              return (
                <option key={index} value={index}>
                  {form.name[i18n.language]}
                </option>
              );
            })}
          </>
        )}
      </select>

      {/* sm */}
      <textarea
        onChange={(e) =>
          setData({
            ...data,
            text: e.target.value + '\n' + userdata.fullname + '\n' + userdata.username,
          })
        }
        placeholder={t('input_txt')}
        className='textarea h-80 textarea-bordered textarea-success textarea-sm w-full mb-3'></textarea>

      <EmailSendBtn data={data} getToken={getToken}></EmailSendBtn>
    </>
  );
};

export default FeedbackEventBody;
