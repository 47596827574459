import React, { useState, useEffect, useRef, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useProSidebar } from 'react-pro-sidebar';

//<a href='#' class='flex items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100'>
//bg-gradient-to-b from-white from-90%

const CardForEvent = ({ children, settings, setShowModal }) => {
  const { t, i18n } = useTranslation();
  const { broken } = useProSidebar();

  //function show() {
  //  document.getElementById('my_modal_2').close();
  //  document.getElementById('my_modal_2').showModal();
  //}

  const styles = {
    col1: 'w-full p-4 bg-white shadow-2xl border border-gray-200 rounded-xl',
    col2: 'w-full p-4 bg-white shadow-2xl border border-gray-200 rounded-xl',
    col3: 'h-auto aspect-square select-none to-gray-300 overflow-y-scroll p-4 bg-white shadow-2xl border border-gray-200 rounded-xl hover:bg-gray-50 cursor-pointer',
  };

  useEffect(() => {
    if (broken)
      styles.col3 =
        'w-full select-none to-gray-300 overflow-y-hidden p-4 bg-white shadow-2xl border border-gray-200 rounded-xl hover:bg-gray-50 cursor-pointer';
    setColsNumber();
  }, [broken]);

  const [style, setStyle] = React.useState(styles.col3);

  function setColsNumber() {
    switch (settings.colsnum) {
      case 1:
        setStyle(styles.col1);
        break;
      case 2:
        setStyle(styles.col2);
        break;
      case 3:
        setStyle(styles.col3);
        break;
      default:
        setStyle(styles.col3);
    }
  }

  function onClick() {
    if (!broken) setShowModal(true);
  }

  {
    /*<div className='lg:tooltip tooltip-bottom' data-tip={t('click_to_open')}>*/
  }
  return (
    <div onClick={() => onClick()} class={style}>
      <div>{children}</div>
    </div>
  );
};

export default CardForEvent;
