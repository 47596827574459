var MULI_PORTAL_MODE = false;
var PREFIX = window.location.pathname.split('/')[1];
if (!PREFIX) PREFIX = 'portaller';
export const URL_PREFIX = MULI_PORTAL_MODE ? PREFIX : '';
//======nikolsoborsocial=============================================================
const nikolsoborsocial_url = 'https://nikolsoborsocial.org';
const nikolsoborsocial_api_prex = '/api';
const nikolsoborsocial_base_url = nikolsoborsocial_url + nikolsoborsocial_api_prex;
//======portaller====================================================================
const portaller_url = 'https://portaller.cloud';
const portaller_api_prex = '/' + URL_PREFIX + '/api';
const portaller_base_url = portaller_url + portaller_api_prex;
//======nikolsoborsocial_dev=========================================================
const nikolsoborsocial_dev_url = 'http://localhost:3000/api';
//======portaller_dev================================================================
const portaller_dev_url = 'http://localhost:3000/' + URL_PREFIX + '/api';
//===================================================================================
export const URL = nikolsoborsocial_base_url;

console.log('===========Application maunted!=============');
console.log('URL_PREFIX =', URL_PREFIX);
